import React, { useEffect, useRef, useState } from "react";

import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import cx from "classnames";
import styles from "./ConsentDetails.module.scss";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { useQuery, useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useParams } from "react-router-dom";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import { Modal } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Button } from "@mui/material";
import parse from "html-react-parser";
import SignatureCanvas from "react-signature-canvas";
import { isMobile } from "mobile-device-detect";
import { useNavigate } from "react-router-dom";
import Authenticate from "./Authenticate";
import { findIndex } from "lodash";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import logo from "../assets/logo.png";
import ReactPlayer from "react-player";
import { Document, Page, pdfjs } from "react-pdf";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import { toast } from "react-toastify";
import signatureImage from "./signature.jpg";
import draftToHtml from "draftjs-to-html";
import pdfConvert from "pdf-img-convert";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import CircularProgress from "@material-ui/core/CircularProgress";
const steps = ["Consent Details", "Videos", "Consent form", "Fp17DC form"];

export default function ConsentDetails() {
  let { consentId } = useParams();
  const videoPlayerRef = useRef(null);
  let navigate = useNavigate();

  const [selectedVideo, setSelectedVideo] = React.useState<any>(null);
  const [selectedVideoPlayListIndex, setSelectedVideoPlayListIndex] =
    React.useState<any>(0);
  const [selectedVideoIndex, setSelectedVideoIndex] = React.useState<any>(0);
  const [playlistTabValue, setPlaylistTabValue] = React.useState(1);
  const [activeStep, setActiveStep] = React.useState(0);
  const [showSign, setShowSign] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [name, setName] = React.useState("");
  const [openAuthenticate, setOpenAuthenticate] = React.useState(true);
  const [isSignature, setIsSignature] = React.useState(false);
  const [currentVideoTime, setCurrentVideoTime] = React.useState(0);
  const [showWelcome, setShowWelcome] = React.useState(true);
  const [requirefp17dc, setRequirefp17dc] = React.useState(false);

  const [openModal, setOpenModal] = React.useState(false);
  const [pdfUrl, setPdfUrl] = React.useState("");
  const [numPages, setNumPages] = React.useState(null);
  const [isNhsTreatmentSigned, setIsNhsTreatmentSigned] = React.useState(false);
  const [isPrvTreatmentSigned, setIsPrvTreatmentSigned] = React.useState(false);
  const [nhsChecked, setNhsChecked] = React.useState(false);
  const [prvChecked, setPrvChecked] = React.useState(false);
  const [isSucces, setIsSucces] = React.useState(false);
  const [selectedOption, setSelectedOption] = useState("signature");

  useEffect(() => {
    if (showSign) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [showSign]);

  const treatmentDetails = useQuery(
    ["treatmentDetails", consentId],
    async () => {
      const { data } = await axios.get(`/patient/consent_bundle/${consentId}`);
      return data;
    },
    {
      onSuccess: (data) => {
        setRequirefp17dc(data.require_fp17dc);
        const playListIndex = findIndex(data.playlists, { status: true });
        if (playListIndex > -1) {
          const videoIndex = findIndex(
            data.playlists[playListIndex]?.playlist_items,
            { status: true }
          );
          if (videoIndex > -1) {
            setSelectedVideoPlayListIndex(playListIndex);
            setPlaylistTabValue(playListIndex + 1);
            setSelectedVideo(
              data.playlists[playListIndex].playlist_items[videoIndex]
            );
            setSelectedVideoIndex(videoIndex);
          }
        }
      },
      enabled: !openAuthenticate,
      refetchOnWindowFocus: false,
    }
  );

  const markAsOpen = useMutation(() => {
    return axios.put(
      `/consent_bundle/${treatmentDetails.data.id}/mark_consent_bundle_as_opened`
    );
  });

  const addVideoLog = useMutation(
    (data: {
      type: string;
      timeStamp: number;
      videoId: string | undefined;
    }) => {
      if (data.videoId) {
        return axios.post(
          `/consent_bundle/${treatmentDetails.data.id}/video/${data.videoId}/log`,
          data
        );
      } else {
        return null;
      }
    }
  );

  const videoPlayerEvent = (videoId, e) => {
    addVideoLog.mutate({
      type: e.type,
      timeStamp: e.type === "seeking" ? currentVideoTime : e.target.currentTime,
      videoId,
    });
  };

  const savePauseEvent = () => {
    addVideoLog.mutate({
      type: "pause",
      timeStamp: currentVideoTime,
      videoId: selectedVideo?.id,
    });
  };

  const playNextVideo = () => {
    let playListIndex = selectedVideoPlayListIndex;
    const isLastVideo =
      treatmentDetails?.data.playlists[playListIndex].playlist_items.length ===
      selectedVideoIndex + 1;
    if (isLastVideo) {
      playListIndex = findIndex(
        treatmentDetails?.data.playlists,
        (v, index) => index > selectedVideoPlayListIndex && !!v.status
      );
    }
    if (playListIndex > -1) {
      let videoIndex = findIndex(
        treatmentDetails?.data.playlists[playListIndex]?.playlist_items,
        (v, index) => !!v.status
      );
      if (!isLastVideo) {
        videoIndex = findIndex(
          treatmentDetails?.data.playlists[playListIndex]?.playlist_items,
          (v, index) => index > selectedVideoIndex && !!v.status
        );
      }
      if (videoIndex > -1) {
        hackUpdate(
          treatmentDetails?.data.playlists[playListIndex].playlist_items[
            videoIndex
          ]
        );
        setSelectedVideoPlayListIndex(playListIndex);
        setSelectedVideoIndex(videoIndex);
        setPlaylistTabValue(playListIndex + 1);
      }
    }
  };

  const hackUpdate = (video) => {
    setSelectedVideo(null);
    setTimeout(() => {
      setSelectedVideo(video);
    }, 0);
  };

  const logData = useMutation((data: any) => {
    return axios.post(`/log`, data);
  });

  const nameRef = useRef("");

  const handleDone = (event) => {
    event.preventDefault();

    let data = {};

    if (selectedOption === "signature") {
      data = { image: mySignature.current.toDataURL() };
    } else if (selectedOption === "name") {
      data = { name: nameRef.current };
    }

    sign.mutate(data);
    setOpenModal(false);
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const handleInputChange = (event) => {
    nameRef.current = event.target.value;
  };

  // const CustomModal = ({ open, onClose }) => {
  //   console.log("in");
  //   return (
  //     <Dialog open={open} onClose={onClose}>
  //       <DialogTitle>Select Signature Method</DialogTitle>
  //       <DialogContent>
  //         <Box display="flex" justifyContent="space-around" mb={2}>
  //           {!isMobile && (
  //             <>
  //               <Chip
  //                 label="Signature"
  //                 onClick={() => handleOptionSelect("signature")}
  //                 color={selectedOption === "signature" ? "primary" : "default"}
  //                 style={{ fontSize: "1.25rem", padding: "8px 16px" }}
  //               />
  //               <Chip
  //                 label="Enter Name"
  //                 onClick={() => handleOptionSelect("name")}
  //                 color={selectedOption === "name" ? "primary" : "default"}
  //                 style={{ fontSize: "1.25rem", padding: "8px 16px" }}
  //               />
  //             </>
  //           )}
  //         </Box>

  //         {selectedOption === "name" && !isMobile && (
  //           <form onSubmit={handleDone}>
  //             <TextField
  //               label="Your Full Name"
  //               variant="filled"
  //               onChange={handleInputChange}
  //               fullWidth
  //               autoFocus
  //             />
  //             <Button
  //               type="submit"
  //               variant="contained"
  //               color="success"
  //               style={{ marginTop: "16px" }}
  //             >
  //               Save
  //             </Button>
  //           </form>
  //         )}

  //         {selectedOption === "signature" && (
  //           <div className={styles.signPad}>
  //             <SignatureCanvas
  //               penColor="#243474"
  //               ref={mySignature}
  //               canvasProps={{
  //                 className: styles.signCanvas,
  //                 width: isMobile ? 300 : 600,
  //                 height: isMobile ? 200 : 400,
  //               }}
  //               onBegin={() => setIsSignature(true)}
  //             />
  //             <div className={styles.signFooter}>
  //               <IconButton aria-label="erase" onClick={eraseSignature}>
  //                 <DeleteIcon />
  //               </IconButton>
  //               <Button
  //                 variant="contained"
  //                 color="success"
  //                 size="large"
  //                 onClick={handleDone}
  //               >
  //                 Save
  //               </Button>
  //             </div>
  //           </div>
  //         )}
  //       </DialogContent>
  //       <DialogActions>
  //         <Button onClick={onClose} color="secondary">
  //           Cancel
  //         </Button>
  //       </DialogActions>
  //     </Dialog>
  //   );
  // };

  const handleFinishClick = async () => {
    try {
      await axios.put(
        `/patient/consent_bundle/${treatmentDetails.data.id}/complete_consent_bundle`
      );
      navigate(`/${treatmentDetails.data.id}/thankyou`);
    } catch (error) {
      console.error("Error completing consent bundle:", error);
    }
  };

  const [editorState, setEditorState] = React.useState<any>(
    EditorState.createEmpty()
  );

  const handleNhsTreatmentCheckbox = async (e) => {
    if (!nhsChecked) {
      // Only allow toggling on
      try {
        const response = await axios.put(
          `/consent_bundle/${treatmentDetails.data.id}/mark_consent_bundle_as_signed_for_fp17dc_nhs_treatment`
        );

        setNhsChecked(true);
        setIsNhsTreatmentSigned(true);
      } catch (error) {
        console.error("Error marking NHS treatment as signed:", error);
      }
    }
  };

  const handlePrvTreatmentCheckbox = async (e) => {
    if (!prvChecked) {
      try {
        const response = await axios.put(
          `/consent_bundle/${treatmentDetails.data.id}/mark_consent_bundle_as_signed_for_fp17dc_private_treatment`
        );

        setPrvChecked(true);
        setIsPrvTreatmentSigned(true);
      } catch (error) {
        console.error("Error marking Private treatment as signed:", error);
      }
    }
  };

  const skipToNextVideo = () => {
    if (treatmentDetails?.data?.playlists) {
      const currentPlaylist = treatmentDetails.data.playlists[selectedVideoPlayListIndex];
  
      // Calculate the next video index
      const nextIndex = selectedVideoIndex + 1;
  
      // Check if the next video index exceeds the current playlist length
      if (nextIndex >= currentPlaylist.playlist_items.length) {
        // Move to the next playlist
        const nextPlaylistIndex = selectedVideoPlayListIndex + 1;
  
        // Check if the next playlist exists
        if (nextPlaylistIndex < treatmentDetails.data.playlists.length) {
          const nextPlaylist = treatmentDetails.data.playlists[nextPlaylistIndex];
  
          // Check if the next playlist has any videos
          if (nextPlaylist.playlist_items.length > 0) {
            // Set to the first video of the next playlist
            setSelectedVideoPlayListIndex(nextPlaylistIndex);
            setSelectedVideoIndex(0); // Reset to the first video
            setSelectedVideo(nextPlaylist.playlist_items[0]); // Set the first video in the next playlist
            setPlaylistTabValue(nextPlaylistIndex + 1); // Update the tab value to reflect the new playlist
            logData.mutateAsync({
              type: "info",
              description: "Moved to the next playlist",
              event: "Patient Module Access",
              data: {
                consentBundleId: treatmentDetails.data.id,
                video: nextPlaylist.playlist_items[0].title,
              },
            });
          } else {
            console.log("Next playlist has no videos available.");
          }
        } else {
          console.log("No more playlists available.");
        }
      } else {
        // If there are more videos in the current playlist
        const nextVideo = currentPlaylist.playlist_items[nextIndex];
        setSelectedVideoIndex(nextIndex);
        setSelectedVideo(nextVideo);
        logData.mutateAsync({
          type: "info",
          description: "Skip to next video",
          event: "Patient Module Access",
          data: {
            consentBundleId: treatmentDetails.data.id,
            video: nextVideo.title,
          },
        });
      }
    }
  };
  

  const { mutate: sign } = useMutation(
    (data: { image?: string; name?: string }) => {
      return axios.post(
        `/consent_bundle/${treatmentDetails.data.id}/upload_signature`,
        data
      );
    },
    {
      onSuccess: () => {
        window.scrollTo(0, document.body.scrollHeight);
        toast.success("Signed successfully");
        setIsFormSuccess(true);
        handleModalClose();
      },
      onError: () => {
        toast.error("Something went wrong");
        setIsFormSuccess(false);
      },
    }
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeInput, setActiveInput] = useState("");
  const [userFullName, setUserFullName] = useState("");
  const [isFormSuccess, setIsFormSuccess] = useState(false);
  const [isSignButtonEnabled, setIsSignButtonEnabled] = useState(false);
  const [isSignatureCaptured, setIsSignatureCaptured] = useState(false);
  const [isImageVisible, setIsImageVisible] = useState(true);

  const mySignature = React.useRef(null);
  const isMobile = window.innerWidth <= 768;

  const eraseSignature = () => {
    const signature = mySignature.current;
    setIsSignature(false);
    signature?.clear();
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
    setActiveInput("signature");
  };
  const handleModalClose = () => {
    setIsModalOpen(false);
    setActiveInput("signature");
    setUserFullName("");
    eraseSignature();
    setIsSignatureCaptured(false);
    setIsImageVisible(true);
  };

  const handleChipClick = (inputType) => {
    setActiveInput(inputType);
    if (inputType === "signature") {
      setIsSignatureCaptured(false);
    }
  };

  const handleFullNameChange = (e) => setUserFullName(e.target.value);

  const handleSignatureSave = () => {
    if (mySignature.current) {
      const signatureData = mySignature.current.toDataURL();
      sign({ image: signatureData });
    }
    setActiveInput("");
    setIsImageVisible(true);
  };

  const handleNameSave = () => {
    sign({ name: userFullName });
    setActiveInput("");
    setIsImageVisible(true);
  };

  const [isLoadingViewPdf, setIsLoadingViewPdf] = React.useState(false);
  const PdfViewer = () => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const consentForm = draftToHtml(rawContentState);
    setIsLoadingViewPdf(true);
    axios({
      url: `${process.env.REACT_APP_BASE_URL}consent_bundle/${treatmentDetails.data.id}/generate_fp17dc_form_pdf`,
      method: "GET",
      responseType: "blob", // important
      data: { consentForm },
    }).then((response) => {
      const href = URL.createObjectURL(response.data);
      setPdfUrl(href);
      setIsLoadingViewPdf(false);
    });
  };

  const tabListRef = useRef(null);

  const handleTabChange = (e, v) => {
    setPlaylistTabValue(v);
    logData.mutateAsync({
      type: "info",
      description: "Click video playlist",
      event: "Patient Module Access",
      data: {
        consentBundleId: treatmentDetails.data.id,
        playlistId: treatmentDetails.data.playlists[v - 1].id,
      },
    });

    // Scroll the selected tab into view
    const tab = tabListRef.current.querySelector(`[data-value="${v}"]`);
    if (tab) {
      tab.scrollIntoView({ behavior: "smooth", inline: "center" });
    }
  };

  return (
    <div className={styles.consentContainer}>
      {openAuthenticate ? (
        <Authenticate
          openAuthenticate={openAuthenticate}
          setOpenAuthenticate={setOpenAuthenticate}
          consentId={consentId}
        />
      ) : (
        <>
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          {activeStep === 0 && (
            <div className={styles.consentSection}>
              <div className={styles.column}>
                <Box mb={3}>
                  <div className={styles.banner}>
                    <img src={logo} alt="Logo" className={styles.bannerLogo} />
                    <p className={styles.bannerText}>
                      Welcome to Medicube,
                      <br />
                      where your dentist has curated trusted content for your
                      review.
                    </p>
                  </div>
                </Box>

                <h2 className={styles.header}>Consent Details</h2>

                {treatmentDetails?.data?.patient && (
                  <div className={styles.column}>
                    <p className={styles.dataItem}>
                      Name: {treatmentDetails.data.patient.title}{" "}
                      {treatmentDetails.data.patient.first_name}{" "}
                      {treatmentDetails.data.patient.last_name}
                    </p>
                    <p className={styles.subDataItem}>
                      Email: {treatmentDetails.data.patient.email_address}
                    </p>
                    <p className={styles.subDataItem}>
                      Mobile: {treatmentDetails.data.patient.mobile_phone}
                    </p>
                    <p className={styles.subDataItem}>
                      Phone: {treatmentDetails.data.patient.home_phone}
                    </p>
                    <p className={styles.subDataItem}>
                      Address: {treatmentDetails.data.patient.address_line_1}{" "}
                      {treatmentDetails.data.patient.address_line_2}
                    </p>
                    <p className={styles.subDataItem}>
                      NHS Number: {treatmentDetails.data.patient.nhs_number}
                    </p>
                  </div>
                )}
              </div>
              <div className={styles.footer}>
                <Button
                  variant="outlined"
                  color="secondary"
                  csize="large"
                  disabled
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => {
                    markAsOpen.mutate();
                    logData.mutateAsync({
                      type: "info",
                      description:
                        "Click next button to move from Consent Details to Videos",
                      event: "Patient Module Access",
                      data: {
                        consentBundleId: treatmentDetails.data.id,
                      },
                    });
                    setActiveStep(1);
                  }}
                >
                  Next
                </Button>
              </div>
            </div>
          )}
          {activeStep === 1 && (
            <div className={styles.consentSection}>
              <div className={styles.column}>
                <h2 className={styles.header}>Videos</h2>
              </div>
              {treatmentDetails?.data?.playlists?.length > 0 ? (
                <div className={styles.videos}>
                  <div className={styles.playListContainer}>
                    <TabContext value={playlistTabValue}>
                      <TabList
                        ref={tabListRef}
                        onChange={handleTabChange}
                        variant="scrollable"
                        className={styles.tabList}
                      >
                        {treatmentDetails?.data?.playlists?.map(
                          (list, index) => {
                            if (list.status) {
                              return (
                                <Tab
                                  label={list.title}
                                  value={index + 1}
                                  key={index}
                                  data-value={index + 1} // Add data attribute for easy selection
                                />
                              );
                            }
                            return null;
                          }
                        )}
                      </TabList>
                      {treatmentDetails?.data?.playlists?.map(
                        (playlist, playlistIndex) => {
                          if (playlist.status) {
                            return (
                              <TabPanel
                                className={styles.playList}
                                value={playlistIndex + 1}
                                key={playlistIndex}
                              >
                                {playlist?.playlist_items?.map(
                                  (video, index) => {
                                    if (video.status) {
                                      return (
                                        <div
                                          className={styles.video}
                                          key={index}
                                        >
                                          <Chip
                                            className={cx(styles.description, {
                                              [styles.active]:
                                                selectedVideoIndex === index,
                                            })}
                                            label={video.title}
                                            avatar={
                                              <img
                                                className={styles.thumbnail}
                                                src={video.thumbnail}
                                                alt={video.title}
                                              />
                                            }
                                            variant="outlined"
                                            onClick={() => {
                                              savePauseEvent();
                                              hackUpdate(video);
                                              setSelectedVideoPlayListIndex(
                                                playlistIndex
                                              );
                                              setSelectedVideoIndex(index);
                                              logData.mutateAsync({
                                                type: "info",
                                                description:
                                                  "Click video thumbnail",
                                                event: "Patient Module Access",
                                                data: {
                                                  consentBundleId:
                                                    treatmentDetails.data.id,
                                                  video: video.title,
                                                },
                                              });
                                            }}
                                          />
                                        </div>
                                      );
                                    }
                                    return null;
                                  }
                                )}
                              </TabPanel>
                            );
                          } else {
                            return null;
                          }
                        }
                      )}
                    </TabContext>
                    <Box mt={4} ml={5}>
                      <div className={styles.buttonContainer}>
                        <Button
                          variant="contained"
                          color="info"
                          onClick={skipToNextVideo}
                          endIcon={<SkipNextIcon />}
                        >
                          Skip Video
                        </Button>
                      </div>
                    </Box>
                  </div>

                  <div className={styles.player}>
                    {selectedVideo && (
                      <>
                        <ReactPlayer
                          url={selectedVideo?.url}
                          controls
                          playing={false}
                          onPause={() =>
                            videoPlayerEvent(selectedVideo?.id, {
                              type: "pause",
                            })
                          }
                          onPlay={() =>
                            videoPlayerEvent(selectedVideo?.id, {
                              type: "play",
                            })
                          }
                          onSeek={(seekTime) =>
                            videoPlayerEvent(selectedVideo?.id, { seekTime })
                          }
                          onEnded={() => {
                            playNextVideo();
                            videoPlayerEvent(selectedVideo?.id, {
                              type: "ended",
                            });
                          }}
                          onProgress={({ playedSeconds }) =>
                            setCurrentVideoTime(playedSeconds)
                          }
                          width="100%"
                          height="480px"
                        />
                      </>
                    )}
                  </div>
                </div>
              ) : (
                <p className={styles.noVideos}>
                  Your dentist hasn’t assigned any videos for your viewing
                </p>
              )}
              <div className={styles.footer}>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="large"
                  onClick={() => {
                    savePauseEvent();
                    setActiveStep(0);
                    logData.mutateAsync({
                      type: "info",
                      description:
                        "Click back button to move from Videos to Consent Details",
                      event: "Patient Module Access",
                      data: {
                        consentBundleId: treatmentDetails.data.id,
                      },
                    });
                  }}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => {
                    savePauseEvent();
                    setActiveStep(2);
                    logData.mutateAsync({
                      type: "info",
                      description:
                        "Click next button to move from Videos to Consent form",
                      event: "Patient Module Access",
                      data: {
                        consentBundleId: treatmentDetails.data.id,
                      },
                    });
                  }}
                >
                  Next
                </Button>
              </div>
            </div>
          )}

          {activeStep === 2 && (
            <div className={styles.consentSection}>
              <div className={styles.row}>
                <div className={styles.column}>
                  <div className={styles.consentForm}>
                    {parse(treatmentDetails?.data?.consent_form?.body || "")}
                  </div>
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={() => setChecked((val) => !val)}
                      />
                    }
                    label="I agree to sign this document electronically"
                  />

                  <Button
                    variant="contained"
                    color={isFormSuccess ? "success" : "warning"}
                    size="large"
                    disabled={!checked}
                    onClick={handleModalOpen}
                  >
                    Sign
                  </Button>
                </div>
              </div>

              <Modal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                aria-labelledby="modal-signature"
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: { xs: "90%", sm: "600px" },
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                    borderRadius: "8px",
                    overflow: "hidden",
                  }}
                >
                  <h2 id="modal-signature">Sign Consent</h2>

                  {isMobile ? (
                    <div
                      className={styles.signPad}
                      style={{
                        width: "100%",
                        border: "2px solid #243474",
                        margin: "10px 0",
                        padding: "10px",
                        borderRadius: "8px",
                        boxSizing: "border-box",
                        overflow: "hidden",
                        position: "relative",
                      }}
                    >
                      <SignatureCanvas
                        penColor="#243474"
                        ref={mySignature}
                        canvasProps={{
                          className: styles.signCanvas,
                          width: Math.min(window.innerWidth * 0.9, 400),
                          height: Math.min(window.innerWidth * 0.9 * 0.5, 300),
                        }}
                        onBegin={() => {
                          setIsSignatureCaptured(true);
                          setIsImageVisible(false);
                        }}
                      />

                      {isImageVisible && (
                        <img
                          src={signatureImage}
                          alt="Draw Here"
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "50%",
                            maxWidth: "200px",
                            height: "auto",
                            opacity: 0.5,
                            pointerEvents: "none",
                          }}
                        />
                      )}
                    </div>
                  ) : (
                    <>
                      <Chip
                        label="Signature"
                        variant={
                          activeInput === "signature" ? "filled" : "outlined"
                        }
                        onClick={() => handleChipClick("signature")}
                        sx={{
                          mr: 2,
                          fontSize: "1.2rem",
                          padding: "10px 20px",
                          backgroundColor:
                            activeInput === "signature"
                              ? "#FFA500"
                              : "transparent",
                          color:
                            activeInput === "signature" ? "white" : "inherit",
                        }}
                      />
                      <Chip
                        label="Enter Name"
                        variant={activeInput === "name" ? "filled" : "outlined"}
                        onClick={() => handleChipClick("name")}
                        sx={{
                          fontSize: "1.2rem",
                          padding: "10px 20px",
                          backgroundColor:
                            activeInput === "name" ? "#FFA500" : "transparent",
                          color: activeInput === "name" ? "white" : "inherit",
                        }}
                      />

                      {activeInput === "signature" && (
                        <div
                          className={styles.signPad}
                          style={{
                            border: "2px solid #243474",
                            margin: "10px 0",
                            padding: "10px",
                            borderRadius: "8px",
                            overflow: "hidden",
                            position: "relative",
                          }}
                        >
                          <SignatureCanvas
                            penColor="#243474"
                            ref={mySignature}
                            canvasProps={{
                              className: styles.signCanvas,
                              width: 600,
                              height: 400,
                            }}
                            onBegin={() => {
                              setIsSignatureCaptured(true);
                              setIsImageVisible(false);
                            }}
                          />

                          {isImageVisible && (
                            <img
                              src={signatureImage}
                              alt="Draw Here"
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                width: "50%",
                                maxWidth: "200px",
                                height: "auto",
                                opacity: 0.5,
                                pointerEvents: "none",
                              }}
                            />
                          )}
                        </div>
                      )}

                      {activeInput === "name" && (
                        <>
                          <TextField
                            label="Full Name"
                            variant="outlined"
                            fullWidth
                            value={userFullName}
                            onChange={(e) => setUserFullName(e.target.value)}
                            sx={{ mt: 2 }}
                          />
                        </>
                      )}
                    </>
                  )}

                  {/* Button Container */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "10px",
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => setIsModalOpen(false)} // Close modal action
                      sx={{ alignSelf: "flex-end" }} // Align at the bottom left
                    >
                      Close
                    </Button>

                    <div style={{ display: "flex", alignItems: "center" }}>
                      {activeInput === "signature" && (
                        <>
                          <IconButton
                            aria-label="erase"
                            onClick={eraseSignature}
                          >
                            <DeleteIcon />
                          </IconButton>
                          <Button
                            variant="contained"
                            color="success"
                            size="large"
                            onClick={handleSignatureSave}
                            disabled={!isSignatureCaptured}
                            sx={{ ml: 2 }} // Add margin to the left for spacing
                          >
                            Save Signature
                          </Button>
                        </>
                      )}

                      {activeInput === "name" && (
                        <Button
                          variant="contained"
                          color="success"
                          size="large"
                          onClick={handleNameSave}
                          disabled={!userFullName}
                          sx={{ ml: 2 }} // Add margin to the left for spacing
                        >
                          Save Name
                        </Button>
                      )}
                    </div>
                  </div>
                </Box>
              </Modal>

              <div className={styles.footer}>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="large"
                  onClick={() => {
                    setActiveStep(1);
                    logData.mutateAsync({
                      type: "info",
                      description:
                        "Click back button to move from Consent Form to Videos",
                      event: "Patient Module Access",
                      data: {
                        consentBundleId: treatmentDetails.data.id,
                      },
                    });
                  }}
                >
                  Back
                </Button>
                {isFormSuccess &&
                  (requirefp17dc ? (
                    <Button
                      variant="contained"
                      size="large"
                      onClick={() => {
                        PdfViewer();
                        savePauseEvent();
                        setActiveStep(3);
                        logData.mutateAsync({
                          type: "info",
                          description:
                            "Click next button to move from Consent form to new step",
                          event: "Patient Module Access",
                          data: { consentBundleId: treatmentDetails.data.id },
                        });
                      }}
                    >
                      Next
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      size="large"
                      onClick={handleFinishClick}
                    >
                      Finish
                    </Button>
                  ))}
              </div>
            </div>
          )}

          {activeStep === 3 && (
            <div className={styles.consentSection}>
              <div className={styles.row}>
                <div className={styles.column}>
                  <h2 className={styles.header}>FP17DC Form</h2>
                  {pdfUrl && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      {isLoadingViewPdf && (
                        <div style={{ textAlign: "center", marginTop: "20px" }}>
                          <CircularProgress />{" "}
                          {/* You can replace this with any loader or icon */}
                          <p>Loading FP17DC form...</p>
                        </div>
                      )}

                      {pdfUrl && isMobile ? (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <embed
                            src={`${pdfUrl}#toolbar=0`}
                            style={{
                              width: "100%",
                              height: "400px",
                              border: "none",
                              overflow: "auto",
                            }}
                            type="application/pdf"
                            title="PDF Viewer"
                          />
                        </div>
                      ) : (
                        <div
                          style={{
                            width: "200%",
                            height: "900px",
                            display: "flex",
                            justifyContent: "center",
                            overflow: "auto",
                          }}
                        >
                          {pdfUrl && (
                            <iframe
                              src={`${pdfUrl}#toolbar=0`}
                              style={{
                                width: "220%",
                                height: "2500px",
                                border: "none",
                                overflow: "scroll",
                                display: "block",
                                pointerEvents: "none",
                              }}
                              title="PDF Viewer"
                              scrolling="yes"
                            />
                          )}
                        </div>
                      )}

                      <div
                        style={{
                          marginTop: "20px",
                          padding: "10px",
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          gap: "15px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: isMobile ? "column" : "row",
                            justifyContent: isMobile
                              ? "flex-start"
                              : "space-between",
                            gap: "15px",
                          }}
                        >
                          {/* NHS Treatment Toggle */}
                          <label
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontSize: "16px",
                              gap: "10px",
                              cursor: "pointer",
                              flex: 1,
                            }}
                          >
                            <input
                              type="checkbox"
                              checked={nhsChecked}
                              onChange={handleNhsTreatmentCheckbox}
                              style={{ display: "none" }}
                            />
                            <span
                              style={{
                                width: "250px",
                                height: "50px",
                                backgroundColor: nhsChecked
                                  ? "#007bff"
                                  : "#ff5722",
                                borderRadius: "25px",
                                position: "relative",
                                transition: "background-color 0.3s",
                                display: "inline-block",
                                textAlign: "center",
                                lineHeight: "50px",
                                color: "white",
                                fontWeight: "bold",
                                cursor: nhsChecked ? "default" : "pointer",
                              }}
                              onClick={handleNhsTreatmentCheckbox}
                            >
                              {nhsChecked
                                ? "NHS Signed"
                                : "Apply Signature for NHS"}
                            </span>
                          </label>

                          {/* Private Treatment Toggle */}
                          <label
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontSize: "16px",
                              gap: "10px",
                              cursor: "pointer",
                              flex: 1,
                            }}
                          >
                            <input
                              type="checkbox"
                              checked={prvChecked}
                              onChange={handlePrvTreatmentCheckbox}
                              style={{ display: "none" }}
                            />
                            <span
                              style={{
                                width: "250px",
                                height: "50px",
                                backgroundColor: prvChecked
                                  ? "#007bff"
                                  : "#ff5722",
                                borderRadius: "25px",
                                position: "relative",
                                transition: "background-color 0.3s",
                                display: "inline-block",
                                textAlign: "center",
                                lineHeight: "50px",
                                color: "white",
                                fontWeight: "bold",
                                cursor: prvChecked ? "default" : "pointer",
                              }}
                              onClick={handlePrvTreatmentCheckbox}
                            >
                              {prvChecked
                                ? "Private Signed"
                                : "Apply Signature for Private"}
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className={styles.footer}>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="large"
                  onClick={() => {
                    setActiveStep(2);
                    logData.mutateAsync({
                      type: "info",
                      description:
                        "Click back button to move from new step to Consent Form",
                      event: "Patient Module Access",
                      data: { consentBundleId: treatmentDetails.data.id },
                    });
                  }}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  onClick={handleFinishClick}
                  disabled={!isNhsTreatmentSigned}
                  style={{
                    backgroundColor: isNhsTreatmentSigned
                      ? "#28a745"
                      : "#6c757d",
                    color: "white",
                    borderColor: isNhsTreatmentSigned ? "#28a745" : "#6c757d",
                    transition: "background-color 0.3s ease",
                  }}
                >
                  Finish
                </Button>
              </div>
            </div>
          )}

          {showSign && (
            <div className={styles.signPad}>
              <SignatureCanvas
                penColor="#243474"
                ref={mySignature}
                canvasProps={{
                  className: isSignature
                    ? styles.signCanvas
                    : styles.signCanvasBackground,
                }}
                onBegin={() => setIsSignature(true)}
              />
              <IconButton
                aria-label="close"
                className={styles.close}
                onClick={() => {
                  setShowSign(false);
                }}
              >
                <CloseIcon />
              </IconButton>
              <div className={styles.signFooter}>
                <IconButton aria-label="erase" onClick={() => eraseSignature()}>
                  <DeleteIcon />
                </IconButton>
                <Button
                  variant="contained"
                  color="success"
                  size="large"
                  disabled={sign.isLoading}
                  onClick={() => {
                    sign.mutate({ image: mySignature.current.toDataURL() });
                  }}
                >
                  Done
                </Button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
